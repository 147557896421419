<template>
  <div>
    <p>123123</p>
    <photo-selector type="banner" v-model="photo"></photo-selector>
  </div>
</template>

<script>
export default {
  data: () => ({
    photo: [],
  }),
};
</script>